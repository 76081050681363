import React from "react";

export const MedalIcon = ({ width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    enableBackground="new 0 0 489.619 489.619"
    width={width}
    height={height}
    viewBox="0 0 489.619 489.619"
  >
    <g>
      <path
        d="m437.728 440.962-58.945-12.973-18.269 57.674c-1.314 4.147-6.93 4.755-9.1.985l-72.921-126.674c35.92-7.783 67.88-26.229 92.42-51.866l72.223 125.477c2.167 3.764-1.166 8.311-5.408 7.377z"
        fill="#fd646f"
      />
      <path
        d="m437.729 440.962-54.484-11.992c-2.527-.556-5.06.907-5.841 3.373l-16.889 53.319c-1.314 4.147-6.93 4.755-9.1.985l-62.471-108.529-10.45-18.145c35.92-7.783 67.88-26.229 92.42-51.866l10.37 18.004 61.854 107.473c2.165 3.766-1.168 8.312-5.409 7.378z"
        fill="#fd646f"
      />
      <path
        d="m437.728 440.959-54.48-11.99c-2.53-.55-5.06.91-5.84 3.37l5.864-18.222c.791-2.458 3.324-3.909 5.845-3.349l46.881 10.421 7.14 12.4c2.17 3.76-1.17 8.31-5.41 7.37z"
        fill="#fc4755"
      />
      <path
        d="m212.276 358.454-74.074 128.656c-2.17 3.77-7.786 3.162-9.1-.985l-16.89-53.32c-.781-2.466-3.315-3.929-5.841-3.373l-54.483 11.991c-4.242.934-7.575-3.613-5.408-7.377l68.893-119.69c25.72 24.384 60.413 38.09 96.903 44.098z"
        fill="#fd646f"
      />
      <g fill="#fc4755">
        <path d="m125.123 332.16-61.39 106.651-11.842 2.609c-4.242.935-7.576-3.612-5.409-7.377l58.642-101.883 15.858-27.622c4.27 4.042 8.73 7.884 13.38 11.495z" />
        <path d="m145.428 474.549-7.23 12.56c-2.17 3.77-7.78 3.17-9.1-.98l-16.89-53.32c-.78-2.47-3.31-3.93-5.84-3.38l18.68-4.109c2.526-.556 5.059.907 5.84 3.372z" />
        <path d="m117.04 311.311 48.719 21.97 46.517 25.173-12.473 21.65c-34.78-7.061-67.05-23.25-93.72-47.092-.32-.281-.64-.562-.96-.853z" />
        <path d="m381.283 326.112-10.37-18.004-54.874 24.22-37.546 27.646 4.22 7.327 10.363 17.998 58.338 101.348c2.17 3.77 7.786 3.162 9.1-.985l3.715-11.728-57.985-101.001c28.071-9.768 53.625-25.66 75.039-46.821z" />
      </g>
      <path
        d="m426.298 182.038c0 48.927-19.25 93.361-50.58 126.07-24.54 25.637-56.5 44.083-92.42 51.866-12.41 2.688-25.28 4.102-38.49 4.102-10.08 0-19.96-.823-29.59-2.407-34.62-5.697-65.95-21.224-91.03-43.631-1.36-1.204-2.69-2.427-4.01-3.681-4.68-4.433-9.12-9.118-13.32-14.022-4.02-4.714-7.8-9.639-11.33-14.754-10.6-15.336-18.91-32.398-24.44-50.673-5.05-16.73-7.77-34.474-7.77-52.869 0-100.534 81.26-182.039 181.49-182.039 18.33 0 36.03 2.728 52.7 7.804 18.23 5.527 35.23 13.862 50.53 24.504 5.1 3.541 10.01 7.332 14.7 11.374 6.28 5.376 12.18 11.184 17.67 17.362 28.55 32.167 45.89 74.554 45.89 120.994z"
        fill="#faa515"
      />
      <ellipse
        cx="244.808"
        cy="182.037"
        fill="#fad207"
        rx="129.992"
        ry="130.385"
      />
      <path
        d="m374.798 182.038c0 72.007-58.2 130.383-129.99 130.383s-129.99-58.376-129.99-130.383c0-5.437.33-10.793.98-16.048 7.88 64.444 62.64 114.334 129.01 114.334s121.13-49.89 129.01-114.334c.65 5.256.98 10.612.98 16.048z"
        fill="#faa515"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m244.808 51.656c-71.79 0-129.99 58.376-129.99 130.383s58.2 130.383 129.99 130.383 129.99-58.376 129.99-130.383-58.2-130.383-129.99-130.383zm0 245.72c-63.41 0-114.99-51.736-114.99-115.337s51.58-115.337 114.99-115.337 114.99 51.736 114.99 115.337-51.58 115.337-114.99 115.337z"
        fill="#faf063"
      />
      <path
        d="m249.299 109.937 17.857 36.477c.727 1.486 2.142 2.516 3.78 2.751l40.131 5.766c4.107.59 5.75 5.637 2.777 8.531l-29.107 28.343c-1.182 1.151-1.72 2.81-1.439 4.435l6.936 40.075c.709 4.094-3.596 7.216-7.268 5.271l-35.817-18.98c-1.464-.776-3.218-.776-4.682 0l-35.817 18.98c-3.672 1.946-7.977-1.176-7.268-5.271l6.936-40.075c.281-1.625-.257-3.284-1.439-4.435l-29.107-28.343c-2.973-2.895-1.33-7.941 2.777-8.531l40.131-5.766c1.637-.235 3.052-1.265 3.78-2.751l17.857-36.477c1.829-3.736 7.153-3.736 8.982 0z"
        fill="#faa515"
      />
    </g>
  </svg>
);

export const GoalIcon = ({ width = 512, height = 512 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512 512"
  >
    <path
      d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0 0"
      fill="#fe0050"
    />
    <path
      d="m256 0v512c141.160156 0 256-114.839844 256-256s-114.839844-256-256-256zm0 0"
      fill="#e10555"
    />
    <path
      d="m256 427c-94.289062 0-171-76.710938-171-171s76.710938-171 171-171 171 76.710938 171 171-76.710938 171-171 171zm0 0"
      fill="#d9e7f3"
    />
    <path
      d="m256 85v342c94.289062 0 171-76.710938 171-171s-76.710938-171-171-171zm0 0"
      fill="#c8d4df"
    />
    <path
      d="m256 337c-44.664062 0-81-36.335938-81-81s36.335938-81 81-81 81 36.335938 81 81-36.335938 81-81 81zm0 0"
      fill="#fe0050"
    />
    <path
      d="m256 175v162c44.664062 0 81-36.335938 81-81s-36.335938-81-81-81zm0 0"
      fill="#e10555"
    />
    <path
      d="m256 271c-3.839844 0-7.679688-1.464844-10.605469-4.394531-5.859375-5.855469-5.859375-15.355469 0-21.210938l110.308594-110.308593c5.855469-5.859376 15.355469-5.859376 21.210937 0 5.859376 5.855468 5.859376 15.351562 0 21.210937l-110.308593 110.308594c-2.925781 2.929687-6.765625 4.394531-10.605469 4.394531zm0 0"
      fill="#2d2d55"
    />
    <path
      d="m376.914062 135.085938-131.519531 131.519531c2.925781 2.929687 6.765625 4.394531 10.605469 4.394531s7.679688-1.464844 10.605469-4.394531l110.308593-110.308594c5.859376-5.855469 5.859376-15.355469 0-21.210937zm0 0"
      fill="#0a0a48"
    />
    <path
      d="m425 165.640625c-.417969 0-.839844-.019531-1.261719-.050781l-58.6875-4.949219c-7.289062-.617187-13.074219-6.398437-13.6875-13.6875l-4.953125-58.691406c-.371094-4.402344 1.21875-8.742188 4.34375-11.867188l63.636719-63.636719c4.128906-4.128906 10.277344-5.488281 15.761719-3.480468 5.480468 2.003906 9.300781 7.007812 9.792968 12.824218l3.882813 46.066407 46.070313 3.886719c5.816406.492187 10.820312 4.3125 12.828124 9.796874 2.003907 5.480469.644532 11.628907-3.484374 15.757813l-63.636719 63.636719c-2.824219 2.824218-6.644531 4.394531-10.605469 4.394531zm0 0"
      fill="#ffc300"
    />
    <path
      d="m489.898438 72.054688-46.066407-3.886719-88.128906 88.128906c2.433594 2.433594 5.699219 4.035156 9.34375 4.34375l58.691406 4.949219c.421875.03125.839844.050781 1.261719.050781 3.960938 0 7.78125-1.570313 10.605469-4.394531l63.636719-63.636719c4.128906-4.128906 5.488281-10.277344 3.484374-15.761719-2.007812-5.480468-7.011718-9.300781-12.828124-9.792968zm0 0"
      fill="#fab400"
    />
  </svg>
);
