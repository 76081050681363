import { Container, Grid } from "@material-ui/core";
import React from "react";
import { SectionItem } from "../interfaces/cv-data-types";
import { logAction } from "../utils/common";
import {
  AboutMeContent,
  EmailSocialNetwork,
  GeneralContent,
  Images,
} from "../utils/cv-data";

const About: React.FC = () => {
  return (
    <section id={SectionItem.About} className="s-about target-section">
      <div className="row narrow section-intro has-bottom-sep">
        <div className="col-full text-center">
          <h3>{AboutMeContent.sectionTitle}</h3>
        </div>
      </div>

      <Container maxWidth="lg">
        <Grid container className="about-content">
          <Grid item md={8}>
            <h3>{AboutMeContent.aboutMeTitle}</h3>
            {AboutMeContent.aboutMeDescription}
          </Grid>

          <Grid item container md={4} justify="center" alignItems="center">
            <div className="about-content-img-container">
              <div className="about-content-img-border">
                <img
                  className="about-content-img"
                  src={Images.aboutMe}
                  alt="about-img"
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container className="about-content" justify="space-around">
          <Grid item sm={5} xs={12}>
            <a
              href="/docs/cv.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="btn 
btn--primary full-width"
              onClick={() =>
                logAction("About", "User pressed Download CV Button")
              }
            >
              {GeneralContent.downloadCVBtn}
            </a>
          </Grid>

          <Grid item sm={5} xs={12}>
            <a
              href={EmailSocialNetwork.url}
              className="btn full-width"
              onClick={() =>
                logAction("About", "User pressed Contact Me Button")
              }
            >
              {GeneralContent.contactMeBtn}
            </a>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default About;
