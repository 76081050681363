import React from "react";
import { HomeContent, SocialNetworks, Images } from "../utils/cv-data";
import { SectionItem } from "../interfaces/cv-data-types";
import { logAction } from "../utils/common";

const Home: React.FC = () => {
  return (
    <section
      id={SectionItem.Home}
      className="s-home page-hero target-section"
      data-parallax="scroll"
      data-image-src={Images.homeContentBackground}
      data-natural-width="3000"
      data-natural-height="2000"
      data-position-y="center"
    >
      <div className="overlay"></div>
      <div className="shadow-overlay"></div>

      <div className="home-content">
        <div className="row home-content__main">
          <h3>{HomeContent.title}</h3>
          <h1 style={{ whiteSpace: "pre-line" }}>{HomeContent.subtitle}</h1>

          <div className="home-content__buttons">
            <a
              href={`#${SectionItem.About}`}
              className="smoothscroll btn btn--stroke"
              onClick={() => logAction("Home", `User click aboutMeBtn`)}
            >
              {HomeContent.aboutMeBtn}
            </a>
            <a
              href={`#${SectionItem.Portfolio}`}
              className="smoothscroll btn btn--stroke"
              onClick={() => logAction("Home", `User click latestProjectsBtn`)}
            >
              {HomeContent.latestProjectsBtn}
            </a>
          </div>

          <div className="home-content__scroll">
            <a
              href={`#${SectionItem.About}`}
              className="scroll-link smoothscroll"
              onClick={() => logAction("Home", `User click scrollDownText`)}
            >
              <span>{HomeContent.scrollDownText}</span>
            </a>
          </div>
        </div>
      </div>

      {/* Social Networks */}
      <ul className="home-social">
        {console.log(SocialNetworks)}
        {SocialNetworks.map((socialNetwork) => (
          <li key={socialNetwork.name}>
            <a
              href={socialNetwork.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                logAction(
                  "Home",
                  `User click ${socialNetwork.name} Social Network`
                )
              }
            >
              <i className={`im ${socialNetwork.img}`} aria-hidden="true"></i>
              <span>{socialNetwork.name}</span>
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Home;
