export type SocialNetwork = {
  name: string;
  img: string;
  url: string;
};

export type CustomTimelineItem = {
  from: string;
  to?: string;
  title: string;
  institute: string;
  gpa?: number;
  features: string[];
  img: string;
  url: string;
};

export type CustomPortfolioItem = {
  link: string;
  title: string;
  subtitle: string;
  description: string | React.ReactElement;
  previewImg: string;
  projectImg: string;
  size: string;
};

export enum SectionItem {
  Home = "home",
  About = "about",
  Experience = "experience",
  Honors = "honors",
  Portfolio = "portfolio",
}

export enum BarSectionItem {
  Home = "home",
  About = "about",
  Experience = "experience",
  //Honors = "honors",
  Portfolio = "portfolio",
}

export type HonorItem = {
  type: "achievement" | "award";
  title: string;
  organization?: string;
  date: string;
  description: string | React.ReactElement;
};
