import React from "react";
import { logAction } from "../utils/common";
import { SocialNetworks, GeneralContent } from "../utils/cv-data";

type FooterProps = {};

const Footer: React.FC<FooterProps> = (_) => {
  return (
    <footer>
      <div className="row">
        <div className="col-full">
          {/* <div className="footer-logo">
            <a className="footer-site-logo" href="#0">
              <img src="images/logo.png" alt="Homepage" />
            </a>
          </div> */}

          <ul className="footer-social">
            {SocialNetworks.map((socialNetwork) => (
              <li key={socialNetwork.name}>
                <a
                  href={socialNetwork.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={`im ${socialNetwork.img}`} aria-hidden="true" />
                  <span>{socialNetwork.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="row footer-bottom">
        <div className="col-twelve">
          <div className="copyright">
            <span>{GeneralContent.copyrightText}</span>
          </div>

          <div className="go-top">
            <a
              className="smoothscroll"
              title="Back to Top"
              href="#top"
              onClick={() =>
                logAction("Footer", "User pressed Back To Top Button")
              }
            >
              <i className="im im-arrow-up" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
