import {
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { SectionItem } from "../interfaces/cv-data-types";
import { HonorItemsPerYear, HonorsContent } from "../utils/cv-data";
import { GoalIcon, MedalIcon } from "./icons";

type HonorsProps = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    itemsContainer: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    itemContainer: {
      paddingTop: theme.spacing(1),
    },
    itemTitleContainer: {
      display: "flex",
      alignItems: "center",
    },
    itemTitle: {
      fontWeight: "bold",
      paddingLeft: theme.spacing(1.5),
    },
    itemDescription: {
      paddingTop: theme.spacing(1),
      textAlign: "justify",
    },
    itemIcon: {
      minWidth: 30,
      minHeight: 30,
    },
  })
);

const Honors: React.FC<HonorsProps> = (_) => {
  const classes = useStyles();

  return (
    <section id={SectionItem.Honors} className="s-about target-section">
      <div className="row narrow section-intro has-bottom-sep">
        <div className="col-full text-center">
          <h3>{HonorsContent.sectionTitle}</h3>
        </div>
      </div>
      <Container maxWidth="md" className={classes.root}>
        {Object.keys(HonorItemsPerYear)
          .sort()
          .reverse()
          .map((year) => {
            return (
              <div key={year}>
                <Typography variant="h2"> {year}</Typography>
                <div className={classes.itemsContainer}>
                  {HonorItemsPerYear[year].map((honorItem, index) => (
                    <div key={index} className={classes.itemContainer}>
                      <div className={classes.itemTitleContainer}>
                        <div className={classes.itemIcon}>
                          {honorItem.type === "award" ? (
                            <MedalIcon width={30} height={30} />
                          ) : (
                            <GoalIcon width={30} height={30} />
                          )}
                        </div>
                        <Typography
                          variant="h4"
                          color="primary"
                          className={classes.itemTitle}
                        >
                          {honorItem.title}
                        </Typography>
                      </div>
                      {/* {honorItem.organization && (
                      <Typography variant="h4">
                        {honorItem.organization}
                      </Typography>
                    )} */}
                      <Typography variant="h5">
                        {honorItem.description}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
      </Container>
    </section>
  );
};

export default Honors;
