import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import "./css/base.css";
import "./css/fonts.css";
import "./css/iconic/iconmonstr-iconic-font.min.css";
import "./css/main.css";
import "./css/vendor.css";
import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga";
import App from "./app";

const theme = createMuiTheme({ palette: { primary: { main: "#E85A4F" } } });

/** Set Google Analaytics */
const trackingId = "UA-106544289-2"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
