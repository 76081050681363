import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Theme,
  Container,
} from "@material-ui/core";
import React, { useCallback } from "react";
import SwipeableViews from "react-swipeable-views";
import {
  AcademicTimelineItems,
  BackgroundContent,
  LaboralTimelineItems,
} from "../utils/cv-data";
import CustomTimeline from "./timeline";
import { SectionItem } from "../interfaces/cv-data-types";

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    display: "inline-block",
    fontFamily: `"montserrat-semibold", sans-serif`,
    fontSize: "1.2rem",
    letterSpacing: "0.3rem",
    height: "5.4rem",
    lineHeight: "calc(5.4rem - 0.6rem)",
    padding: "0 3rem",
    textAlign: "center",
    WebkitTransition: "all 0.3s ease-in-out",
    transition: "all 0.3s ease-in-out",
    color: "#18273a", // Like black
    backgroundColor: "#fff", // Like gray
    width: "100%",
  },
  tabsContainer: {
    borderRadius: theme.spacing(1),
  },
  swipeableViews: {
    display: "flex",
    justifyContent: "center",
  },
}));

type TabPanelProps = {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const Background: React.FC = (_) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const handleChange = useCallback(
    (_: React.ChangeEvent<{}>, newValue: number) => {
      setTabIndex(newValue);
    },
    []
  );
  const handleChangeIndex = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  return (
    <section
      id={SectionItem.Experience}
      className="s-background target-section"
    >
      <div className="row narrow section-intro has-bottom-sep">
        <div className="col-full text-center">
          <h3>{BackgroundContent.sectionTitle}</h3>
        </div>
      </div>

      <Container maxWidth="lg">
        <Grid container className="about-content" justify="center">
          <Grid item sm={8} xs={11}>
            <Paper className={classes.tabsContainer}>
              <Tabs
                classes={{ root: classes.tabsContainer }}
                value={tabIndex}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab
                  label={BackgroundContent.workTab}
                  classes={{ root: classes.btn }}
                />
                <Tab
                  label={BackgroundContent.academicTab}
                  classes={{ root: classes.btn }}
                />
              </Tabs>
            </Paper>
          </Grid>

          <Grid item sm={12} xs={12}>
            <SwipeableViews
              index={tabIndex}
              onChangeIndex={handleChangeIndex}
              className={classes.swipeableViews}
            >
              <TabPanel value={tabIndex} index={0}>
                <CustomTimeline items={LaboralTimelineItems} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <CustomTimeline items={AcademicTimelineItems} />
              </TabPanel>
            </SwipeableViews>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Background;
