import React from "react";
import { BarSectionItem } from "../interfaces/cv-data-types";
import { GeneralContent } from "../utils/cv-data";

type HeaderProps = {};

const Header: React.FC<HeaderProps> = (_) => {
  return (
    <header className="s-header">
      <div className="header-logo">
        <a href="/">
          <img src="favicon.ico" className="site-logo" alt="Homepage" />
        </a>
      </div>

      <nav className="header-nav-wrap">
        <ul className="header-nav">
          {Object.values(BarSectionItem).map((sectionItemData) => (
            <li key={sectionItemData} className="current">
              <a
                className="smoothscroll"
                href={`#${sectionItemData}`}
                title={sectionItemData}
              >
                {sectionItemData.toUpperCase()}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <a className="header-menu-toggle" href="#0">
        <span>{GeneralContent.menuText}</span>
      </a>
    </header>
  );
};

export default Header;
