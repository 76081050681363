import React from "react";
import About from "./components/about";
import Background from "./components/background";
import Footer from "./components/footer";
import Header from "./components/header";
import Home from "./components/home";
import Honors from "./components/honors";
import PhotoSwipe from "./components/photoswipe";
import Portfolio from "./components/works";

type AppProps = {};

const App: React.FC<AppProps> = (_) => {
  return (
    <div className="App">
      <Header />
      <Home />
      <About />
      <Background />
      <Honors />
      <Portfolio />
      <Footer />
      <PhotoSwipe />
    </div>
  );
};

export default App;
