import React from "react";
import { SectionItem } from "../interfaces/cv-data-types";
import { PortfolioContent, PortofolioItems } from "../utils/cv-data";
import PortfolioItem from "./portfolio_item";

// const useStyles = makeStyles((theme: Theme) => createStyles({}));

const Portfolio: React.FC = () => {
  return (
    <section id={SectionItem.Portfolio} className="s-works target-section">
      <div className="row narrow section-intro has-bottom-sep">
        <div className="col-full">
          <h3>{PortfolioContent.sectionTitle}</h3>
          {/* <h1>See My Latest Projects.</h1> */}
        </div>
      </div>

      <div className="row masonry-wrap">
        <div className="masonry">
          {PortofolioItems.map((item, index) => (
            <PortfolioItem item={item} key={index} />
          ))}
        </div>
      </div>
      {/* <!-- end masonry --> */}
    </section>
  );
};

export default Portfolio;
