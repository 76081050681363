import React from "react";
import {
  SocialNetwork,
  CustomTimelineItem,
  CustomPortfolioItem,
  HonorItem,
} from "../interfaces/cv-data-types";
import { logAction } from "./common";

export const GeneralContent = {
  websiteTitle: "Angel Igareta - Online CV",
  author: "Angel Igareta",
  downloadCVBtn: "Download My CV (WIP 😅)",
  contactMeBtn: "Contact Me",
  copyrightText: "© Copyright Angel Igareta 2021",
  menuText: "Menu",
};

export const Images = {
  homeContentBackground: "/images/teide.jpg",
  aboutMe: "/images/me.jpg",
};

export const HomeContent = {
  title: "Hi, my name is",
  subtitle: `Angel Igareta.
            Enthusiast of Data Science and Machine Learning.`,
  latestProjectsBtn: "Latest Projects",
  aboutMeBtn: "More About Me",
  scrollDownText: "Scroll Down",
};

export const AboutMeContent = {
  sectionTitle: "About",
  aboutMeTitle: "Hello, I'm Angel!",
  aboutMeDescription: (
    <>
      <p>
        Born and raised in the Canary Islands, now living in complete opposite
        weather in the hearth of Sweden: Stockholm. I am currently working as a
        Data Science Intern in Credit Risk and Underwriting Modelling at Klarna.
      </p>

      <p>
        I am passionate about using data being collected in different areas to
        create new solutions that can help improve people's lives. In my spare
        time, I love to cook, travel and meet new people from all over the
        world.
      </p>
    </>
  ),
};

export const BackgroundContent = {
  sectionTitle: "Experience",
  academicTab: "Academic",
  workTab: "Work",
};

export const PortfolioContent = {
  sectionTitle: "Portfolio",
};

export const HonorsContent = {
  sectionTitle: "Honors",
};

export const EmailSocialNetwork: SocialNetwork = {
  name: "Email",
  img: "im-mail",
  url: "mailto:angel@igareta.com",
};

// Allowed icons: https://iconmonstr.com/iconicfont/
export const SocialNetworks: SocialNetwork[] = [
  {
    name: "Medium",
    img: "im im-newspaper-o",
    url: "https://angeligareta.medium.com/",
  },
  {
    name: "LinkedIn",
    img: "im-linkedin",
    url: "https://www.linkedin.com/in/angeligareta",
  },
  {
    name: "GitHub",
    img: "im-github",
    url: "https://github.com/angeligareta",
  },
  EmailSocialNetwork,
];

export const AcademicTimelineItems: CustomTimelineItem[] = [
  {
    from: "08/15/2020",
    to: "06/15/2021",
    title: "MSc in ICT Innovation in Data Science",
    institute: "KTH Royal Institute of Technology",
    features: [
      "Performed a real case study for ABB with an international and cross-functional team.",
      "Highest grade in all related Data Science subjects: Data-Intensive Computing, Data Mining, and Scalable Machine Learning and Deep Learning.",
    ],
    img: "/images/kth.jpg",
    url: "https://www.kth.se/en",
  },
  {
    from: "07/31/2020",
    to: "08/15/2020",
    title: "EIT Digital Summer School in Secure e-governance",
    institute: "Tallinn University of Technology",
    features: [
      "Acquired insight into interoperability between different domains such as law, IT, security, business, design thinking, and engineering in one of the world's most advanced e-societies.",
      "Development of an innovative e-service for the Estonian Government using Telegram bots and AWS tools.",
    ],
    img: "/images/taltech.jpg",
    url: "https://www.taltech.ee/en",
  },
  {
    from: "09/01/2019",
    to: "06/15/2021",
    title: "Innovation & Entrepreneurship Minor",
    institute: "EIT Digital Master School",
    features: [
      "Double-degree Master in Data Science, Innovation and Entrepreneurship at two different leading European universities, entry year at UPM (Spain) and exit year at KTH (Sweden).",
      "Acquiring skills related to entrepreneurship, problem-solving, and innovation from a human-centered perspective.",
      "Awarded with the Merit EIT Digital scholarship.",
    ],
    img: "/images/eit.jpg",
    url: "https://masterschool.eitdigital.eu/",
  },
  {
    from: "09/01/2019",
    to: "06/01/2020",
    title: "MSc in Data Science",
    institute: "Universidad Politécnica de Madrid",
    features: [
      "Entry year of the EIT Digital Master's Degree.",
      "Implemented cloud computing projects using Scala, Apache Spark, Flink and Hadoop.",
      "Implemented data analysis and visualization projects with R, Python and TensorFlow.",
      "Brief research on the latest cyber-attacks against e-health.",
    ],
    img: "/images/upm.jpg",
    url: "http://www.upm.es/internacional",
  },
  {
    from: "07/15/2019",
    to: "08/15/2019",
    title: "Summer Program in Data Mining and Business Intelligence",
    institute: "Ben-Gurion University of the Negev",
    gpa: 4.0,
    features: [
      "Acquired both theoretical and practical knowledge of data mining and machine learning to solve cybersecurity problems.",
      "Field trips to leading companies in Tel Aviv: IBM, Verint, and Checkpoint.",
      "Final project focused on the field of ”Adversarial Learning in Medicine”, developed using Python and TensorFlow in Google Colab.",
    ],
    img: "/images/ben_gurion.jpg",
    url: "https://in.bgu.ac.il/en/pages/default.aspx",
  },
  {
    from: "09/01/2018",
    to: "01/31/2019",
    title: "BSc in Computer Science",
    institute: "University College Cork",
    gpa: 4.0,
    features: [
      "Participation in Erasmus+ Study Mobility Program.",
      "Improved knowledge in Web Security, 3D Graphics, and Parallel Computing.",
    ],
    img: "/images/ucc.jpg",
    url: "https://www.ucc.ie/en/",
  },
  {
    from: "09/01/2015",
    to: "06/01/2019",
    title: "BSc in Computer Engineering",
    institute: "Universidad de La Laguna",
    gpa: 3.73,
    features: [
      "Obtained the highest mark in the Autonomous Systems thesis developed using YoloV3, OpenCV and C++.",
      "First place in an open-source competition with a project in Computer Vision.",
      "Participation in a Local Congress solving a problem of the ACM-ICPC contest.",
    ],
    img: "/images/ull.jpg",
    url: "https://www.ull.es/",
  },
];

export const LaboralTimelineItems: CustomTimelineItem[] = [
  {
    from: "07/05/2021",
    title: "Data Science Summer Intern",
    institute: "Klarna",
    features: [
      "Validation of a unified multi-market approach to deploy Klarna Card credit risk models faster and more efficiently in new markets. Preliminary results show that the chosen strategy could outperform existing models.",
      "Main tools: Apache Airflow, AWS Redshift, Python, SQL, Scikit-learn",
    ],
    img: "/images/klarna.jpeg",
    url: "https://www.klarna.com/",
  },
  {
    from: "01/01/2021",
    to: "06/15/2021",
    title: "Data Science Intern",
    institute: "Babyshop Group",
    features: [
      "Master's Thesis Research on category classification and attribute prediction in children's fashion using Transfer Learning.",
      "Main tools: Python, Tensorflow 2, Keras, NLTK, Numpy, Pandas, and Google Cloud Platform.",
    ],
    img: "/images/babyshop-group.jpg",
    url: "https://career.babyshopgroup.com/",
  },
  {
    from: "09/01/2019",
    to: "09/01/2020",
    title: "Product Designer and Full Stack Developer",
    institute: "Atecresa",
    features: [
      "Designed and developed a revolutionary e-commerce infrastructure that will be used by different clients in the hospitality industry.",
      "Main tools: React Hooks, TypeScript, Next.js, Node.js, AWS, PostgreSQL and GraphQL APIs.",
    ],
    img: "/images/atecresa.jpg",
    url: "https://atecresa.com/",
  },
  {
    from: "04/01/2014",
    to: "01/01/2020",
    title: "Founder and Content Creator",
    institute: "Feeling The Net",
    features: [
      "Creation of tutorials and high quality software and hardware projects, published on YouTube and online media.",
      "Followed by more than 120 thousand followers and obtained a total of 25 million video views.",
      "Assisted to Mobile World Congress 3 years in a row.",
    ],
    img: "/images/feelingthenet.jpg",
    url: "https://www.youtube.com/channel/UChBkxLPlKqEjl7_g3pfIlqw",
  },
  {
    from: "03/01/2019",
    to: "06/01/2019",
    title: "Software Engineer Intern",
    institute: "Open Canarias",
    features: [
      "Development of a single-page web application to visualize the data generated by a source-to-source compilation platform.",
      "Main tools: React Hooks, TypeScript and REST APIs.",
    ],
    img: "/images/opencanarias.jpg",
    url: "https://www.opencanarias.com/",
  },
];

export const PortofolioItems: CustomPortfolioItem[] = [
  {
    title: "Safer Auto",
    subtitle: "Computer Vision",
    description: (
      <p>
        For my BSc degree project I developed a real-time object detection
        system capable of locating and tracking road elements from a video
        stream using YOLOv3. Among the elements to be classified, the model was
        trained to detect traffic lights, vehicles and some types of traffic
        signs. It obtained the highest score and was awarded first place in a
        national open source competition.
        <br />
        <a
          href="https://github.com/angeligareta/SaferAuto"
          onClick={() =>
            logAction("Portfolio", "User click SaferAuto Github Link")
          }
        >
          Github repository
        </a>
      </p>
    ),
    link: "https://github.com/angeligareta/SaferAuto",
    previewImg: "/images/saferauto.jpg",
    projectImg: "/images/saferauto-project.jpg",
    size: "1050 x 575",
  },

  {
    title: "Emojis and Emoticons",
    subtitle: "Sentiment Analysis",
    description: (
      <p>
        Recently, communication has shifted due to the spread of new
        technologies and social networks. With the increase in the number of
        messages exchanged on the Internet, the interest in understanding the
        emotions of users also rises. However, the existing research does not
        provide any clear results regarding the impact of emojis and emoticons
        in the sentiment analysis. This project aims to fill in this gap, by
        studying how the inclusion of pictograms, such as emoticons and emojis,
        affects the sentiment analysis performed by machine learning
        classification algorithms.
        <br />
        <a
          href="https://github.com/angeligareta/pictograms-sentiment-analysis"
          onClick={() =>
            logAction(
              "Portfolio",
              "User click Emojis and Emoticons Github Link"
            )
          }
        >
          Github repository
        </a>
      </p>
    ),
    link: "https://github.com/angeligareta/pictograms-sentiment-analysis",
    previewImg: "/images/emojis-and-emoticons-project.jpg",
    projectImg: "/images/emojis-and-emoticons-project.jpg",
    size: "1050 x 591",
  },
  {
    title: "RestBot",
    subtitle: "Natural Language Processing",
    description: (
      <p>
        RestBot is a project in the domain of Intelligent Systems where I
        developed a chatbot designed for restaurants, with the aim of improving
        the experience of a customer who decides to eat at the restaurant. It
        was implemented using Dialogflow, Firebase and Kotlin.
        <br />
        <a
          href="https://github.com/angeligareta/RestBot-A-bot-for-Restaurants"
          onClick={() =>
            logAction("Portfolio", "User click Restbot Github Link")
          }
        >
          Github repository
        </a>
      </p>
    ),
    link: "https://github.com/angeligareta/RestBot-A-bot-for-Restaurants",
    previewImg: "/images/restbot.jpg",
    projectImg: "/images/restbot-project.jpg",
    size: "1050 x 591",
  },
  {
    title: "LinkedinToCVWebsite",
    subtitle: "Web Development",
    description: (
      <p>
        In my free time, I started developing a tool that will be able to create
        a React CV website that displays your LinkedIn information in a prettier
        and more organized way. The idea is to keep all the professional
        information in one place and use this data to have a more visual
        representation of your introduction, education work experience,
        projects... The main tools being used are Node.js and React Hooks.
        <br />
        <a
          href="https://github.com/angeligareta/LinkedinToCVWebsite"
          onClick={() =>
            logAction("Portfolio", "User click LinkedinToCVWebsite Github Link")
          }
        >
          Github repository
        </a>
      </p>
    ),
    link: "https://github.com/angeligareta/LinkedinToCVWebsite",
    previewImg: "/images/LinkedinToCVWebsite.jpg",
    projectImg: "/images/LinkedinToCVWebsite-project.jpg",
    size: "1050 x 533",
  },
  {
    title: "Cheaper Travelling",
    subtitle: "Data-Intensive Computing",
    description: (
      <p>
        Nowadays, there are several websites that help finding the best route to
        fly from one place to another, such as Kayak, SkyScanner or Google
        Flights. However, none of these platforms take into account alternate
        routes with other methods of transport, such as travelling by boat, bus
        or train. In this project the aim is to work with different data
        streaming APIs to implement a Spark Streaming application that, reading
        from Kafka data stream the origin, destination, departure date, price
        and time ranges, returns the optimal combination of routes, in terms of
        time and price.
        <br />
        <a
          href="https://github.com/angeligareta/cheaper-travelling"
          onClick={() =>
            logAction("Portfolio", "User click Cheaper Travelling Github Link")
          }
        >
          Github repository
        </a>
      </p>
    ),
    link: "https://github.com/angeligareta/cheaper-travelling",
    previewImg: "/images/cheaper-travelling-project.jpg",
    projectImg: "/images/cheaper-travelling-project.jpg",
    size: "1050 x 591",
  },
  {
    title: "Smart Mirror",
    subtitle: "Internet of Things",
    description: (
      <p>
        A smart mirror is a two-way mirror with an electronic display behind it.
        The screen can display different types of information in the form of
        widgets, such as time, date and news updates. On my YouTube channel, I
        created a video explaining how to build it from scratch, using a
        Raspberry Pi and open source code.
        <br />
        <a
          href="https://www.youtube.com/watch?v=EkHRD6s2q2k"
          onClick={() =>
            logAction("Portfolio", "User click SmartMirror YouTube Link")
          }
        >
          {" "}
          YouTube video
        </a>
      </p>
    ),
    link: "https://www.youtube.com/watch?v=EkHRD6s2q2k",
    previewImg: "/images/smart-mirror.jpg",
    projectImg: "/images/smart-mirror-project.jpg",
    size: "1050 x 591",
  },
];

export const HonorItems: HonorItem[] = [
  {
    type: "award",
    title: `Partner Challenge in Junction Connected, 1st Place`,
    organization: "CGI and Tampereen kaupunki - City of Tampere",
    date: "11/08/2020",
    description: (
      <p>
        The partner challenge of <a href="https://www.cgi.com/en">CGI</a> and{" "}
        <a href="https://www.tampere.fi/">City of Tampere</a> at{" "}
        <a href="https://connected.hackjunction.com/">
          Junction Connected Hackathon
        </a>{" "}
        of 2020 tackled the topic of preventing drug use among youth people. Our
        solution{" "}
        <a href="https://www.youtube.com/watch?v=NjskB8TsLaw">
          Learn with Lumi
        </a>{" "}
        aimed to gamify the student journey of learning about drugs. It provides
        a safe space where the child must take care of an avatar. Within this
        scenario, the character will face different situations and the child
        will have to make decisions to help his virtual friend. To achieve this,
        the child can seek guidance from another character in the Lumi game, a
        chatbot powered by Artificial Intelligence.
      </p>
    ),
  },
  {
    type: "award",
    title: `EIT Digital Deephack in "Hack the future of SME Banking", 2nd Place`,
    organization: "Ultrahack and OTP eBIZ",
    date: "12/01/2019",
    description: (
      <p>
        Hackathon organized by <a href="https://ultrahack.org/">Ultrahack</a>{" "}
        and <a href="https://www.otpbank.hu/">OTP</a> with the aim of finding
        solutions for the shortcomings of the current financial management
        processes of SMEs. Our solution consisted of the development of a plugin
        for the <a href="https://www.otpebiz.hu/">eBiz OTP platform</a> that
        reminds users of the payment of invoices and optimizes the task itself,
        resulting in a better credit score for customers.
      </p>
    ),
  },
  {
    type: "award",
    title: `XIII National Open Source University Contest, 1st Place`,
    organization: "University of Seville",
    date: "04/01/2019",
    description: (
      <p>
        Awarded with the best scientific project in the{" "}
        <a href="https://www.concursosoftwarelibre.org/2021/">
          XIII National Open Source University Contest
        </a>
        , an annual contest that promotes the creation and consolidation of the
        open-source community in universities. The project presented was my
        bachelor thesis{" "}
        <a href="https://github.com/angeligareta/SaferAuto">SaferAuto</a>, a
        real-time road element detection system capable of assisting while
        driving, using the{" "}
        <a href="https://pjreddie.com/darknet/yolo/">YoloV3</a> open-source
        framework and with all the implemented code available in{" "}
        <a href="https://github.com/angeligareta/SaferAuto">GitHub</a>.
      </p>
    ),
  },
  /* {
    type: "achievement",
    title: `Participation in 3º Computer Science Student Congress`,
    organization: "University of La Laguna",
    date: "11/01/2017",
    description: (
      <p>
        The aim of this local contest was to solve a training problem of the{" "}
        <a href="https://icpc.global/">
          ACM-ICPC International Collegiate Programming Contest
        </a>
        , annual competition between universities around the world sponsored by
        IBM. I worked on the problem called Fatman.
      </p>
    ),
  }, */
];

// Group honor items by year
export const HonorItemsPerYear: { [index: string]: HonorItem[] } = {};
HonorItems.forEach((honorItem) => {
  const date = new Date(honorItem.date);
  const year = date.getFullYear().toString();
  (HonorItemsPerYear[year] || (HonorItemsPerYear[year] = [])).push(honorItem);
});
