import { CustomTimelineItem } from "../interfaces/cv-data-types";
import ReactGA from "react-ga";

export const formatEducationDate = (date: string) =>
  new Date(date).toLocaleString("default", { month: "long", year: "numeric" });

export const dateSorter = (
  educationItem1: CustomTimelineItem,
  educationItem2: CustomTimelineItem
) => {
  const fromDate1 = Date.parse(educationItem1.from);
  const fromDate2 = Date.parse(educationItem2.from);
  const toDate1 = educationItem1.to && Date.parse(educationItem1.to);

  return fromDate1 === fromDate2
    ? toDate1 === undefined
      ? -1
      : 1
    : fromDate1 < fromDate2
    ? 1
    : -1;
};

export const logAction = (category: string, action: string) => {
  ReactGA.event({
    category,
    action,
  });
};
