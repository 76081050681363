import React from "react";
import { CustomPortfolioItem } from "../interfaces/cv-data-types";
import { logAction } from "../utils/common";

type PortfolioItemProps = {
  item: CustomPortfolioItem;
};

const PortfolioItem: React.FC<PortfolioItemProps> = ({ item }) => {
  return (
    <div className="masonry__brick">
      <div className="item-folio">
        <div className="item-folio__thumb">
          <a
            title={item.title}
            href={item.projectImg}
            className="thumb-link"
            data-size={item.size}
            onClick={() =>
              logAction("Portfolio", `User click ${item.title} Preview Link`)
            }
          >
            <img
              src={item.previewImg}
              srcSet={`${item.previewImg} 1x`}
              alt={item.title}
              width=""
            />
            <span className="shadow-overlay"></span>
          </a>
        </div>

        <div className="item-folio__text">
          <h3 className="item-folio__title">{item.title}</h3>
          <p className="item-folio__cat">{item.subtitle}</p>
        </div>

        <a
          href={item.link}
          className="item-folio__project-link"
          title="Project link"
          onClick={() =>
            logAction(
              "Portfolio",
              `User click ${item.title} Preview Link from Icon`
            )
          }
        >
          <i className="im im-link"></i>
        </a>

        <div className="item-folio__caption">{item.description}</div>
      </div>
      {/* <!-- end item-folio --> */}
    </div>
  );
};

export default PortfolioItem;
