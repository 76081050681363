import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import React from "react";
import { CustomTimelineItem } from "../interfaces/cv-data-types";
import { dateSorter, formatEducationDate, logAction } from "../utils/common";

type CustomTimelineProps = {
  items: CustomTimelineItem[];
};

const useStyles = makeStyles((theme: Theme) => ({
  ul: {
    listStyleType: "'▹ '",
    //color: theme.palette.primary.main,
  },
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "100%",
    transition: "transform .2s" /* Animation */,

    "&:hover": {
      boxShadow: "0 0 3px #18273a",
      transform:
        "scale(1.2)" /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */,
    },
  },
  imgContainer: {
    borderStyle: "none",
    padding: 0,
    width: 65,
    height: 65,
    backgroundColor: theme.palette.common.white,
  },
  separator: {
    margin: theme.spacing(0, 4),
  },
}));

const CustomTimeline: React.FC<CustomTimelineProps> = ({ items }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  return (
    <Timeline>
      {items.sort(dateSorter).map((educationItem, index) => {
        const date = (
          <div className="timeline__header">
            <p className="timeline__timeframe">
              {formatEducationDate(educationItem.from)} -{" "}
              {educationItem.to
                ? formatEducationDate(educationItem.to)
                : "Present"}
            </p>
          </div>
        );

        return (
          <TimelineItem key={index}>
            {!mobile ? (
              <TimelineOppositeContent style={{ maxWidth: 350 }}>
                {date}
              </TimelineOppositeContent>
            ) : (
              <TimelineOppositeContent style={{ display: "none" }} />
            )}
            <TimelineSeparator className={!mobile ? classes.separator : ""}>
              <TimelineDot className={classes.imgContainer}>
                <a
                  href={educationItem.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    logAction(
                      "Background",
                      `User click ${educationItem.institute} Picture`
                    )
                  }
                >
                  <img
                    src={educationItem.img}
                    className={classes.img}
                    alt={educationItem.institute}
                  />
                </a>
              </TimelineDot>
              <TimelineConnector style={{ width: 1 }} />
            </TimelineSeparator>
            <TimelineContent>
              <div className="timeline__header">
                {mobile && date}
                <h3>{educationItem.institute}</h3>
                <h5>
                  {educationItem.title}
                  {educationItem.gpa ? `, GPA: ${educationItem.gpa}` : ""}
                </h5>
              </div>
              {!mobile && (
                <div className="timeline__desc">
                  <ul className={classes.ul}>
                    {educationItem.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </div>
              )}
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default CustomTimeline;
